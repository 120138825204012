<template>
  <header class="header" :id="$options.consts.HEADER_ID">
    <i
      v-observe-visibility="visibilityOptions"
      class="header__top-position-detector"
    />
    <div class="header__background-wrapper">
      <h2 class="header__preview-mode-panel">Preview Mode</h2>

      <a-visibility show :from="$breakpoint.desktopSm" :keep-on-client="true">
        <a-header-desktop
          :menu-list="desktopMenuList"
          class="desktop-header__wrapper"
          @mounted="onDesktopHeaderMounted"
        />
      </a-visibility>

      <a-visibility
        hide
        :from="$breakpoint.desktopSm"
        :keep-on-client="true"
        @visibility-change="onMobileHeaderVisibilityChange"
      >
        <a-header-mobile
          :menu-list="mobileMenuList"
          @mounted="onMobileHeaderMounted"
        />
      </a-visibility>

      <a-progress-bar />
    </div>

    <a-ci-commit-tag />

    <!-- TODO currency strip is going to be implemented at phase 2 according to the client requirements -->
    <!--    <no-ssr>-->
    <!--      <a-visibility hide :on="[$breakpoint.mobile]">-->
    <!--        <fade-transition :duration="150">-->
    <!--          <a-header-currency-strip v-if="!isHeaderCollapsed" />-->
    <!--        </fade-transition>-->
    <!--      </a-visibility>-->
    <!--    </no-ssr>-->
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PROP_TYPES, propValidator } from '@fmpedia/validators'
import { ROUTE, ROUTE_NAME } from 'enums/routes'
import { serverCacheKey } from '@/utils/mixins/serverCacheKey'
import { REFS } from 'enums/external-refs'
import { HEADER_ID } from 'enums/header'
import AProgressBar from '@/components/_layout/AProgressBar/index.vue'
import {
  hydrateWhenVisible,
  hydrateNever
} from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'
import { CLOSE_MOBILE_HEADER_MENU_EVENT } from '@/components/AHeader/AHeaderMobile/enums'

export default {
  name: 'a-header',
  mixins: [serverCacheKey],
  components: {
    AProgressBar,
    AHeaderMobile: hydrateWhenVisible(() => import('./AHeaderMobile'), {
      props: ['menu-list']
    }),
    AHeaderDesktop: hydrateWhenVisible(() => import('./AHeaderDesktop'), {
      props: ['menu-list']
    }),
    ACiCommitTag: hydrateNever(() => import('./ACiCommitTag'))
  },
  props: {
    isDesktopHeaderMounted: propValidator([PROP_TYPES.BOOLEAN], false),
    isMobileHeaderMounted: propValidator([PROP_TYPES.BOOLEAN], false)
  },
  consts: {
    HEADER_ID
  },
  data() {
    return {
      visibilityOptions: {
        callback: this.changeIsHeaderAtTop
      },
      menuItems: {
        Directory: {
          Name: 'Directory',
          Link: this.$env.FM_DIR_DOMAIN_URL,
          ChildItems: null,
          MobileSection: 2
        },
        Events: {
          Name: 'Events',
          Link: 'https://events.financemagnates.com/',
          ChildItems: null,
          MobileSection: 2
        },
        Intelligence: {
          Name: 'Intelligence',
          Link: ROUTE.INTELLIGENCE,
          ChildItems: [
            {
              Name: 'Quarterly Intelligence Reports',
              Link: ROUTE.INTELLIGENCE_PRODUCTS,
              ChildItems: null
            },
            {
              Name: 'Annual Subscription',
              Link: ROUTE.INTELLIGENCE_PRODUCTS_ANNUAL,
              ChildItems: null
            },
            {
              Name: 'Custom Reports',
              Link: {
                name: ROUTE_NAME.INTELLIGENCE,
                hash: '#custom-made-report'
              },
              ChildItems: null
            }
          ],
          MobileSection: 2
        },
        AboutUs: {
          Name: 'About Us',
          Link: ROUTE.ABOUT_US,
          ChildItems: [
            {
              Name: 'Contact us',
              Link: ROUTE.CONTACT_US,
              ChildItems: null
            },
            {
              Name: 'Terms of Use',
              Link: ROUTE.TERMS_OF_USE,
              ChildItems: null
            },
            {
              Name: 'Privacy',
              Link: ROUTE.PRIVACY_POLICY,
              ChildItems: null
            }
          ],
          MobileSection: 2
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      headerAllNews: 'headerAllNews',
      headerEducation: 'headerEducation',
      headerEducationLink: 'headerEducationLink',
      isPreviewMode: 'isPreviewMode'
    }),
    allNews() {
      return {
        Name: 'News',
        Link: 'all-news',
        ChildItems: this.headerAllNews,
        MobileSection: 1
      }
    },
    education() {
      return {
        Name: 'Education',
        Link: this.headerEducationLink,
        ChildItems: this.headerEducation,
        MobileSection: 1,
        attrs: {
          'data-ref': REFS.HEADER_ITEM_EDUCATION
        }
      }
    },
    mobileMenuList() {
      return [
        this.allNews,
        this.education,
        this.menuItems.Directory,
        this.menuItems.Events,
        this.menuItems.Intelligence,
        this.menuItems.AboutUs
      ]
    },
    desktopMenuList() {
      return [
        this.allNews,
        this.education,
        this.menuItems.Directory,
        this.menuItems.Events,
        this.menuItems.Intelligence
      ]
    }
  },
  methods: {
    ...mapActions({
      setHeaderAtTopState: 'setHeaderAtTopState'
    }),
    changeIsHeaderAtTop(isDetectorVisible) {
      this.setHeaderAtTopState(!isDetectorVisible)
    },
    onDesktopHeaderMounted() {
      this.$emit('update:is-desktop-header-mounted', true)
    },
    onMobileHeaderMounted() {
      this.$emit('update:is-mobile-header-mounted', true)
    },
    onMobileHeaderVisibilityChange({ isVisible }) {
      if (!process.client) return

      if (!isVisible) {
        /**
         * We purposefully close the expanded menu when layout changes
         * to desktop. This is done to prevent bugs when scroll is
         * locked on desktop after rotating a device.
         */
        this.$bus.$emit(CLOSE_MOBILE_HEADER_MENU_EVENT)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$padding-large: $banner-width-large + $widget-margin;
$padding-small: 20px;

.header {
  width: 100%;

  $height: (
    $above-desktop-xl: $size-header-expanded,
    $tablet: $tablet-header-height,
    $mobile: $mobile-header-height
  );
  @include setStyleByBreakpoint('height', $height);

  z-index: $z-index-header;
  pointer-events: none;

  .header__background-wrapper {
    position: relative;
    pointer-events: all;
    background: linear-gradient(to right, #79b0ea, #5356d4);
  }

  .header__top-position-detector {
    position: absolute;
    top: -2px;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
    visibility: hidden;
  }

  .header__preview-mode-panel {
    width: 100%;
    height: 0;
    overflow: hidden;
    background: $c--light-yellow;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.7s;
  }

  .desktop-header__wrapper {
    margin: 0 auto;
  }

  @include all-browser-except-safari {
    &.nofit {
      width: calc(100% - 17px);
    }
  }

  @include mobile {
    &.nofit {
      width: 100%;
    }
  }
}
</style>
<style lang="scss">
#__nuxt {
  .global-wrapper.preview-mode .header__preview-mode-panel {
    height: $size-preview-panel;
  }

  .global-wrapper.preview-mode .header {
    $height: (
      $above-desktop-xl: $size-header-expanded + $size-preview-panel,
      $tablet: $tablet-header-height + $size-preview-panel,
      $mobile: $mobile-header-height + $size-preview-panel
    );
    @include setStyleByBreakpoint('height', $height);
  }
}
</style>
