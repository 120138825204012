import * as types from './mutation-types'
import api from '@/utils/api'
import apiV2 from '@/utils/apiV2'
import { SECTION_TYPE } from 'enums/intelligence'

export const state = () => ({
  isLeadCollectorSectionVisible: false,
  leadCollectorSectionTopPosition: 0,
  isStickyLeadCollectorManuallyClosed: false,
  stickyLeadCollectorSettings: {
    title: '',
    text: '',
    gdpr: '',
    buttonText: ''
  }
})

export const getters = {
  isLeadCollectorSectionVisibleOrAbove: state =>
    state.isLeadCollectorSectionVisible ||
    state.leadCollectorSectionTopPosition < 0,
  isStickyLeadCollectorVisible: (state, getters) => {
    return (
      getters.isLeadCollectorSectionVisibleOrAbove &&
      !state.isStickyLeadCollectorManuallyClosed
    )
  },
  stickyLeadCollectorSettings: state => state.stickyLeadCollectorSettings
}

export const actions = {
  setLeadCollectorSectionVisibility(
    { commit },
    { isSectionVisible, sectionTopPosition }
  ) {
    commit(types.SET_INTELLIGENCE_LEAD_COLLECTOR_SECTION_VISIBILITY, {
      isSectionVisible,
      sectionTopPosition
    })
  },
  closeStickyLeadCollector({ commit }) {
    commit(types.SET_IS_INTELLIGENCE_STICKY_LEAD_COLLECTOR_MANUALLY_CLOSED)
  },
  async requestStickyLeadCollector({ commit }) {
    try {
      const { data } = await this.$axiosV2.get(
        apiV2.intelligence.getLayoutSections(),
        {
          params: {
            sectionType: SECTION_TYPE.INSIDER_REPORT
          }
        }
      )

      commit(
        types.SET_INTELLIGENCE_STICKY_LEAD_COLLECTOR,
        data?.Sections?.InsiderReport
      )

      return data
    } catch (err) {
      throw err
    }
  },
  async requestSubmitInsiderReportLead(_, { payload, headerParams }) {
    try {
      const settings = {
        headers: { GoogleCaptchaResponseKey: headerParams.captchaKey }
      }

      await this.$axiosV2.post(
        apiV2.marketing.submitInsiderReportLead(),
        payload,
        settings
      )
    } catch (err) {
      throw err
    }
  },
  async requestSubmitCustomMadeReport(_, { payload, headerParams }) {
    try {
      const settings = {
        headers: { GoogleCaptchaResponseKey: headerParams.captchaKey }
      }

      await this.$axiosV2.post(
        apiV2.marketing.submitCustomMadeReportLead(),
        payload,
        settings
      )
    } catch (err) {
      throw err
    }
  },
  async requestHomepage() {
    try {
      const { data } = await this.$axiosV2.get(
        apiV2.intelligence.getLayoutSections(),
        {
          params: {
            sectionType: [
              SECTION_TYPE.MAIN,
              SECTION_TYPE.CUSTOM_REPORT,
              SECTION_TYPE.INFOGRAPHIC,
              SECTION_TYPE.QUARTERLY_REPORT,
              SECTION_TYPE.TESTIMONIAL
            ].join(',')
          }
        }
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestOurCustomers() {
    try {
      const { data } = await this.$axiosV2.get(
        apiV2.intelligence.getOurCustomers()
      )
      return data.Customers
    } catch (err) {
      throw err
    }
  },
  async requestQuarterlyReportLegacy(_, { queryParams }) {
    try {
      const { data } = await this.$axios.get(api.intelligence.getQir(), {
        params: queryParams
      })
      return data
    } catch (err) {
      throw err
    }
  },
  async requestQuarterlyReport(_, { pathParams }) {
    try {
      const { data } = await this.$axiosV2.get(
        apiV2.intelligence.getQir(pathParams.slug)
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestLatestQuarterlyReport() {
    try {
      const { data } = await this.$axiosV2.get(
        apiV2.intelligence.getLatestQir()
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestPurchaseQirSingle(_, { pathParams, payload, headerParams }) {
    const settings = {
      headers: { GoogleCaptchaResponseKey: headerParams.captchaKey }
    }

    try {
      const { data } = await this.$axiosV2.post(
        apiV2.intelligence.purchaseQirSingle(pathParams.id),
        payload,
        settings
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestPurchaseQirAnnual(_, { payload, headerParams }) {
    const settings = {
      headers: { GoogleCaptchaResponseKey: headerParams.captchaKey }
    }

    try {
      const { data } = await this.$axiosV2.post(
        apiV2.intelligence.purchaseQirBundle(),
        payload,
        settings
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestQirListPage() {
    try {
      const { data } = await this.$axiosV2.get(
        apiV2.intelligence.getLayoutSections(),
        {
          params: {
            sectionType: SECTION_TYPE.QUARTERLY_REPORTS_LIST
          }
        }
      )
      return data?.Sections?.QuarterlyReportsList
    } catch (err) {
      throw err
    }
  },
  async requestQirQuicksearch(_, { queryParams }) {
    try {
      const { data } = await this.$axiosV2.get(
        apiV2.intelligence.getQirList(),
        {
          params: queryParams
        }
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestQirPackages() {
    try {
      const { data } = await this.$axiosV2.get(
        apiV2.intelligence.getQirPackages()
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestAnnualPage() {
    const { data } = await this.$axiosV2.get(
      apiV2.intelligence.getLayoutSections(),
      {
        params: {
          sectionType: SECTION_TYPE.ANNUAL_SUBSCRIPTION
        }
      }
    )
    return data?.Sections?.AnnualSubscription
  },
  async requestAnnualPageLinks() {
    try {
      const { data } = await this.$axiosV2.get(
        apiV2.intelligence.getAnnualPageLinks()
      )
      return data
    } catch (err) {
      throw err
    }
  }
}

export const mutations = {
  [types.SET_INTELLIGENCE_LEAD_COLLECTOR_SECTION_VISIBILITY](
    state,
    { isSectionVisible, sectionTopPosition }
  ) {
    state.isLeadCollectorSectionVisible = isSectionVisible
    state.leadCollectorSectionTopPosition = sectionTopPosition
  },
  [types.SET_IS_INTELLIGENCE_STICKY_LEAD_COLLECTOR_MANUALLY_CLOSED](state) {
    state.isStickyLeadCollectorManuallyClosed = true
  },
  [types.SET_INTELLIGENCE_STICKY_LEAD_COLLECTOR](state, InsiderReport) {
    const { Title, Text, Gdpr, ButtonText } = InsiderReport.Properties
    state.stickyLeadCollectorSettings.title = Title
    state.stickyLeadCollectorSettings.text = Text
    state.stickyLeadCollectorSettings.gdpr = Gdpr
    state.stickyLeadCollectorSettings.buttonText = ButtonText
  }
}
