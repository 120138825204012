export class ElementPositionTracker {
  constructor(targetElement, onPositionChange) {
    this.targetElement = targetElement
    this.onPositionChange = onPositionChange
    this.observer = null
    this.currentPosition = null
    this.ticking = false

    this.boundHandleUpdate = this.handleUpdate.bind(this)
    this.initializeTracking()
  }
  initializeTracking() {
    // Single ResizeObserver for all elements
    this.observer = new ResizeObserver(entries => {
      this.requestUpdate()
    })
    // Observe target and its ancestors up to body
    let element = this.targetElement
    while (element && element !== document.body) {
      this.observer.observe(element)
      element = element.parentElement
    }

    // Single scroll listener on window instead of multiple elements
    window.addEventListener('scroll', this.boundHandleUpdate, {
      passive: true,
      capture: true // This lets us catch all scroll events in the bubble phase
    })

    // Initial position check
    this.checkPosition()
  }
  requestUpdate() {
    if (!this.ticking) {
      requestAnimationFrame(() => {
        this.checkPosition()
        this.ticking = false
      })
      this.ticking = true
    }
  }
  handleUpdate() {
    this.requestUpdate()
  }
  checkPosition() {
    const newPosition = this.getPosition()

    if (this.hasPositionChanged(newPosition)) {
      this.currentPosition = newPosition
      this.onPositionChange(newPosition)
    }
  }
  getPosition() {
    const rect = this.targetElement.getBoundingClientRect()
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop

    return {
      top: Math.round(rect.top + scrollTop),
      bottom: Math.round(rect.bottom + scrollTop),
      left: Math.round(rect.left + scrollLeft),
      right: Math.round(rect.right + scrollLeft),
      width: Math.round(rect.width),
      height: Math.round(rect.height)
    }
  }
  hasPositionChanged(newPosition) {
    if (!this.currentPosition) return true

    // Using ~~ for faster integer conversion
    return (
      ~~this.currentPosition.top !== ~~newPosition.top ||
      ~~this.currentPosition.left !== ~~newPosition.left
    )
  }
  destroy() {
    this.observer.disconnect()
    window.removeEventListener('scroll', this.boundHandleUpdate, {
      capture: true
    })
  }
}
