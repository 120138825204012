import Vue from 'vue'
import {
  endsWith,
  equals,
  isNil,
  omit,
  pathOr,
  pick,
  pipe,
  startsWith
} from 'ramda'
import Cookies from 'js-cookie'
import querystring from 'querystring'
import {
  checkIfFunction,
  generateParsedDomElementsHandlerDeep,
  getBinaryStringFromBooleanArray,
  getElByDataRef,
  isArray,
  isObject,
  remapObjectKeys,
  valuesToLowercase,
  addStartingSlash,
  addEndingSlash,
  removeStartingSlash,
  removeEndingSlashes,
  wrapIntoSlashes,
  unwrapFromSlashes,
  formatDateToOffset,
  getParsedDateString,
  removeAmpSuffix
} from '@fmpedia/helpers'

import {
  applyFallback,
  checkIfLinkHasProtocol,
  getImageDimensions,
  guid,
  isFile,
  isMiddlewareSkipped
} from '@/utils/helpers'

import { FALLBACK_CHAR_WIDTH } from 'shared/ACharWidth/enums'

import { EXTENSIONS_BY_MIME_TYPE, MEDIA_TYPE, HTML_TAG } from '@fmpedia/enums'
import { MEDIA_EXTENSIONS } from 'enums/media-center'
import { IMAGE_SIZE, IMAGE_SUFFIX } from 'enums/images'
import { MODAL } from '@/components/_modals/AModalWrapper'
import { AMP_PAGES, ROUTE } from '@/utils/enums/routes'
import { ONE_SIGNAL_ICON_CONTAINER_ID } from '@/utils/enums/oneSignal'
import { GOOGLE_CAPTCHA_CHALLENGE_SELECTOR } from 'shared/AInvisibleCaptcha'
import {
  getUrlHost,
  isString,
  getBackendErrorCode,
  getBackendErrorEvidence
} from '@/server/helper'
import { PAGE_SCHEME_TYPE } from 'enums/pageSchemes'
import { PROMISE_STATUS } from 'enums/promiseStatus'
import { TOP_STICKY_CONTAINER_ID } from 'enums/header'
import { REFS } from 'enums/external-refs'

export {
  getUrlHost,
  guid,
  getImageDimensions,
  isFile,
  checkIfLinkHasProtocol,
  isString,
  getBackendErrorCode,
  getBackendErrorEvidence,
  addStartingSlash,
  addEndingSlash
}

/* @fmpedia/helpers */
export {
  checkIfFunction,
  generateParsedDomElementsHandlerDeep,
  getBinaryStringFromBooleanArray,
  isArray,
  isObject,
  remapObjectKeys,
  valuesToLowercase
}

/**
 * Resets the data of a Vue component for the specified fields.
 * @param {Object} component - The Vue context of the component to reset the data for.
 * @param {string[]} fieldList - An array of strings representing the names of the fields to reset.
 * @returns {void}
 */
export function resetComponentData(component, fieldList) {
  if (!fieldList || !isArray(fieldList)) {
    console.log('resetComponentData() - fieldList is not provided. Exit.')
    return
  }

  const initialData = component.$options.data.apply(component)
  const newData = pick(fieldList, initialData)
  Object.assign(component.$data, newData)
}

let storeCopy = null
let context = null

export function getContext() {
  return context
}

export function isPreviewMode() {
  return storeCopy.getters.isPreviewMode
}

export function forceUrlFetch(url) {
  if (!url) return ''
  return `${url}?v=${Date.now()}`
}

/**
 * Repeatedly checks for a given condition. If it results in "true" - runs a
 * given callback (fn). If the timeout is reached and the condition is not
 * truthy, the callback never runs.
 *
 * @param fn { Function }
 * @param condition { Function }
 * @param interval { Number }
 * @param timeout { Number }
 * @param executeOnTimeoutFn { Function }
 * @returns {{clearInterval: clearInterval} | undefined}
 */
export function pollUntil({
  fn,
  condition,
  interval = 200,
  timeout = 60 * 1000,
  executeOnTimeoutFn = () => {}
}) {
  if (!fn || !condition) return

  if (condition()) {
    fn()
    return
  }

  let timePassed = 0
  let timeoutId

  function clearInterval() {
    clearTimeout(timeoutId)
  }

  ;(function runWithInterval() {
    timeoutId = setTimeout(() => {
      timePassed += interval

      if (condition()) {
        fn()
        return
      }

      if (timePassed >= timeout) {
        if (checkIfFunction(executeOnTimeoutFn)) {
          executeOnTimeoutFn()
        }

        return
      }

      runWithInterval()
    }, interval)
  })()

  return { clearInterval }
}

export function isStringStartsWith(searchStr = '', str = '') {
  return isString(str) && startsWith(searchStr, str)
}

export function isStringEndsWith(searchStr = '', str = '') {
  return isString(str) && endsWith(searchStr, str)
}

export function replaceString(pattern = '', newSubStr = '', str = '') {
  if (!isString(str)) return
  return str.replace(pattern, newSubStr)
}

function isChildrenValidationPassed(children) {
  return children
    .reduce((acc, child) => {
      if ('isInternalValidationPassed' in child) {
        child.touchInnerValidation()
        return [...acc, child.isInternalValidationPassed]
      }

      if (
        ('validateWithParent' in child.$attrs ||
          'validate-with-parent' in child.$attrs) &&
        child.$v
      ) {
        child.$v.$touch()
        return [
          ...acc,
          !child.$v.$error,
          isChildrenValidationPassed(child.$children)
        ]
      }

      if (child.$children) {
        return [...acc, isChildrenValidationPassed(child.$children)]
      }
      return acc
    }, [])
    .every(isValid => isValid)
}

function scrollToValidationError(
  component,
  { scrollToValidationError, scrollInContainer, searchInContainer }
) {
  if (scrollToValidationError) {
    component.$nextTick(() => {
      const searchContainer = searchInContainer ? `${searchInContainer} ` : ''
      component.$scrollTo(`${searchContainer}.input-group__error`, {
        offset: -screen.height / 2,
        container:
          scrollInContainer === 'body' ||
          !document.querySelector(scrollInContainer)
            ? 'body'
            : scrollInContainer
      })
    })
  }
}

const defaultOptions = {
  scrollToValidationError: true,
  scrollInContainer: 'body'
}

export function isValidationFailed(component, validationOptions = {}) {
  if (!component) return

  let isInvalid
  const validationObject = pathOr(
    null,
    validationOptions.path || [],
    component.$v
  )

  if (validationObject) {
    validationObject.$touch()
    isInvalid = validationObject.$invalid
  }

  const options = Object.assign({}, defaultOptions, validationOptions)
  const isChildrenValidationFailed = !isChildrenValidationPassed(
    component.$children
  )
  isInvalid = isInvalid || isChildrenValidationFailed
  if (isInvalid) {
    scrollToValidationError(component, options)
  }
  return isInvalid
}

export function removeSpaces(value) {
  return value.replace(/\s/g, '')
}

function removePlusFromPhone(phone) {
  return phone.charAt(0) === '+' ? phone.slice(1) : phone
}

export function formatPhone(phone) {
  return removePlusFromPhone(removeSpaces(phone))
}

export function getLinkToFmBucketFile(fileName) {
  const STATIC_PATH = 'static'
  return `${storeCopy.$env.AMAZON_S3_FM_URL}/${STATIC_PATH}/${fileName}`
}

export function getLinkToBackofficeBucketFile(fileName) {
  const STATIC_PATH = 'bo-static'
  return `${storeCopy.$env.AMAZON_S3_FM_URL}/${STATIC_PATH}/${fileName}`
}

export function getLinkToFmDirBucketFile(fileName) {
  const STATIC_PATH = 'static'
  return `${storeCopy.$env.AMAZON_S3_FM_DIR_URL}/${STATIC_PATH}/${fileName}`
}

export function getImageSuffixFromUrlRegexp(suffix) {
  suffix = suffix.replace('.', '')
  return new RegExp(`(^[^?]*)(${suffix})(\\.[^.]*)(\\?.+)?$`)
}

export function replaceImageSuffixWith({ url, oldSuffix, newSuffix }) {
  oldSuffix = oldSuffix.replace('.', '')
  newSuffix = newSuffix.replace('.', '')
  return url.replace(
    getImageSuffixFromUrlRegexp(oldSuffix),
    `$1${newSuffix}$3$4`
  )
}

export function replaceImageUrlSuffix(imageData) {
  try {
    const originalUrl = pathOr('', ['originalUrl'], imageData)
    const neededSize = pathOr('', ['neededSize'], imageData)

    if (Object.keys(IMAGE_SUFFIX).includes(neededSize)) {
      return replaceImageSuffixWith({
        url: originalUrl,
        oldSuffix: IMAGE_SUFFIX[IMAGE_SIZE.ORIGINAL],
        newSuffix: IMAGE_SUFFIX[neededSize]
      })
    } else {
      return originalUrl
    }
  } catch (err) {
    console.log(err)
  }
}

export function getUrlSizeSuffix(url) {
  if (!url) return null

  const regexp = getImageSuffixFromUrlRegexp('_size\\d+')
  const match = url.match(regexp)

  if (!match?.[0]) return null

  return url.replace(regexp, '$2.')
}

export function isImageUrlHasSizeSuffixes(url) {
  if (!url) return false

  return (
    !!url.match(
      getImageSuffixFromUrlRegexp(IMAGE_SUFFIX[IMAGE_SIZE.ORIGINAL])
    )?.[0] || !!getUrlSizeSuffix(url)
  )
}

export function replaceImageUrlSuffixWithOriginal(url) {
  if (!url) return null

  const suffixMatch = getUrlSizeSuffix(url)

  if (!suffixMatch) return url

  return replaceImageSuffixWith({
    url,
    oldSuffix: suffixMatch,
    newSuffix: IMAGE_SUFFIX[IMAGE_SIZE.ORIGINAL]
  })
}

export function encodeToBase64(string) {
  if (string) {
    return Buffer.from(string).toString('base64')
  }
  return null
}

export function getEncodedJwtBody(token) {
  if (!isString(token)) return ''

  const encodedBody = token.split('.')[1]

  return encodedBody || ''
}

export function decodeFromBase64(string) {
  if (string) {
    return Buffer.from(string, 'base64').toString('utf-8')
  }
  return null
}

export function getUserDetailsFromAccessToken(at) {
  const decodedUserDetails = pipe(
    getTokenValue,
    getEncodedJwtBody,
    decodeFromBase64
  )(at)

  try {
    return JSON.parse(decodedUserDetails)
  } catch (err) {
    console.log('Error parsing accessToken')
    return null
  }
}

export function getExtension(path) {
  if (!path) return ''

  return path.slice(path.lastIndexOf('.')).toLowerCase()
}

export function getMimeTypeByExtension(extension) {
  if (!extension) return null

  const result = Object.entries(
    EXTENSIONS_BY_MIME_TYPE
  ).find(([mimeType, extensions]) => extensions.includes(extension))

  return result && result.length ? result[0] || null : null
}

export function checkIfRefreshTokenIsRelevant() {
  const isAuthStatusRequested = storeCopy.getters['auth/isAuthStatusRequested']

  if (!isAuthStatusRequested) return true

  const refreshTokenLastModified = storeCopy.getters['auth/rtlm']
  const refreshTokenLastModifiedFromCookies = Cookies.get('rtlm')
  console.log('refreshTokenLastModified', String(refreshTokenLastModified))
  console.log(
    'refreshTokenLastModifiedFromCookies',
    refreshTokenLastModifiedFromCookies
  )

  if (!process.browser) return true

  return (
    (isNil(refreshTokenLastModified) &&
      isNil(refreshTokenLastModifiedFromCookies)) ||
    String(refreshTokenLastModified) === refreshTokenLastModifiedFromCookies
  )
}

export function getMediaFileType(path) {
  const extension = getExtension(path)
  if (MEDIA_EXTENSIONS[MEDIA_TYPE.IMAGE].includes(extension)) {
    return MEDIA_TYPE.IMAGE
  }
  if (MEDIA_EXTENSIONS[MEDIA_TYPE.VIDEO].includes(extension)) {
    return MEDIA_TYPE.VIDEO
  }
  return MEDIA_TYPE.UNKNOWN
}

export function fileTypeChecker(path) {
  return {
    isImage: getMediaFileType(path) === MEDIA_TYPE.IMAGE,
    isVideo: getMediaFileType(path) === MEDIA_TYPE.VIDEO,
    isUnknown: getMediaFileType(path) === MEDIA_TYPE.UNKNOWN
  }
}

export function formatPrice(value) {
  if (!value) return ''

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function removePaginationPartFromPath(path) {
  return path.replace(/\/page\/\d+\/*$/i, '')
}

function getPathParamsArrayFromString(str) {
  return pipe(
    removePaginationPartFromPath,
    str => removeAmpSuffix(str),
    removeEndingSlashes,
    removeStartingSlash
  )(str).split('/')
}

export function getNthPathParam(str, n) {
  return getPathParamsArrayFromString(str)[n - 1]
}

export function getLastPathParam(str) {
  return getPathParamsArrayFromString(str).pop()
}

export function prettifyPath(path) {
  if (!path || path === ROUTE.HOMEPAGE) return ROUTE.HOMEPAGE

  return wrapIntoSlashes(removeEndingSlashes(path)).toLowerCase()
}

export function stringToArray(str = '') {
  return str && typeof str === 'string'
    ? str.split(',').map(item => item.trim())
    : []
}

export function arrayToString(arr = []) {
  return arr.length ? arr.join(',') : null
}

export function getFullPath(ctx) {
  return `${ctx.$env.DOMAIN_URL}${prettifyPath(ctx.$route.path)}`
}

/**
 *  Get the "previous" and "next" links in the page head, in order to give
 *  search crawler ability to work correctly with infinity scroll or pages with
 *  pagination
 **/
export function getPaginationSeoLinks({
  canonicalUrlWithoutPagination,
  pagesCount,
  page = 1
}) {
  const link = []
  const hasPreviousPage = page > 1
  const hasNextPage = page < pagesCount

  if (hasPreviousPage) {
    const previousPageNumber = page - 1
    const previousCanonicalUrl = `${addEndingSlash(
      canonicalUrlWithoutPagination
    )}page/${previousPageNumber}/`

    link.push({
      rel: 'previous',
      href:
        previousPageNumber === 1
          ? canonicalUrlWithoutPagination
          : previousCanonicalUrl
    })
  }

  if (hasNextPage) {
    const nextPageNumber = +page + 1
    const nextCanonicalUrl = `${addEndingSlash(
      canonicalUrlWithoutPagination
    )}page/${nextPageNumber}/`

    link.push({
      rel: 'next',
      href: nextCanonicalUrl
    })
  }

  return link
}

export function isUrlWithPagination(url = window && window.location.href) {
  return url !== url.replace(/page\/\d+\/+/, '')
}

export function getPageNumberFromUrl(url) {
  if (!url) return null

  const regExp = /page\/(\d+)/gi
  const groups = getFirstRegexGroups(regExp, url)
  return groups.length ? +groups[0] : null
}

export function getPageNumberFromRoute(route) {
  if (!route) {
    console.error(
      'route is not passed to getPageNumberFromRoute() helper method'
    )
    return 0
  }

  const { path } = route
  const pageNumber = getPageNumberFromUrl(path)

  return pageNumber && pageNumber > 0 ? pageNumber - 1 : 0
}

const FIGURE_REGEXP = /<figure[^>]*?>.+?<\/figure>/gims
const IMAGE_SELF_CLOSING_TAG_REGEXP = /<img[^>]+?\/?>/gims
const IMAGE_CLOSING_TAG_REGEXP = /<\/img>/gims

function removeImagesFromHtml(html) {
  if (!html) return ''

  return html
    .replace(FIGURE_REGEXP, '')
    .replace(IMAGE_SELF_CLOSING_TAG_REGEXP, '')
    .replace(IMAGE_CLOSING_TAG_REGEXP, '')
}

export function htmlToText(html) {
  if (!html) return ''

  if (process.client) {
    /**
     * In the scope of this bug: https://adraba.atlassian.net/browse/FMP-13592
     * we've found that if the innerHTML of a created div has images, they are
     * getting loaded. To avoid that, we remove images before the div is
     * created.
     */
    const htmlWithoutImages = removeImagesFromHtml(html)

    const elem = document.createElement('div')
    elem.innerHTML = htmlWithoutImages
    return elem.textContent.trim()
  } else {
    return removeImagesFromHtml(html)
      .replace(/<[^>]+>/g, '')
      .trim()
  }
}

export function countWordsAmount(text) {
  if (!text) return 0

  const delimiters = /[.,:;?!]/gi
  const oneOrMoreSpaces = / {2,}/gi
  return text
    .replace(delimiters, ' ')
    .replace(oneOrMoreSpaces, ' ')
    .replace(/[\r\n]+/gi, ' ')
    .split(/\s/)
    .filter(Boolean).length
}

const AVERAGE_WORDS_PER_SECOND = 4.2
export function calculateTimeRequiredToRead({ wordCount, text = 0 }) {
  const seconds =
    (wordCount || countWordsAmount(text)) / AVERAGE_WORDS_PER_SECOND
  const date = new Date(0, 0, 0, 0, 0, seconds)
  return `PT${date.getMinutes()}M${date.getSeconds()}S`
}

export function isAmpPage(routeName) {
  return AMP_PAGES.includes(routeName)
}

export function isNotAmpPage(routeName) {
  return !isAmpPage(routeName)
}

export function generateSlugFromName(name) {
  const resultingSlug = name
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z \-\d]/g, '') // remove all symbols except letters, spaces and dashes
    .replace(/\s+/g, '-') // replace all spaces with dashes
    .replace(/\.+/g, '-') // replace all dots with dashes
    .replace(/-+/g, '-') // remove repetitive dashes with one dash
    .replace(/^-/, '') // remove starting dash
    .replace(/-$/, '') // remove ending dash

  const isValid = !!resultingSlug.replace('-', '').length

  return isValid ? resultingSlug : null
}

export function closeModal(modalName, delay = 0) {
  if (delay === 0) {
    return Vue.prototype.$bus.$emit(`close-modal-${modalName}`)
  }

  return setTimeout(() => {
    Vue.prototype.$bus.$emit(`close-modal-${modalName}`)
  }, delay)
}

/**
 * This function close all opened modals such as AModalWrapper and AModalWidget
 * To close AModalWidget components we have to use click-away behavior and click
 * somewhere outside modal component. That's why we use header-menu for emulating
 * click.
 */
export function closeAllModals() {
  Object.keys(MODAL).forEach(modalName => {
    closeModal(modalName)
  })
  document.getElementById('header-menu').click()
}

export function openModal(modalName, payload, closeTimeout) {
  Vue.prototype.$bus.$emit(`open-modal-${modalName}`, payload)

  /**
   * Be aware that we don't handle clearTimeout because in our application we
   * don't have cases when user can reopen the same modal window which was
   * automatically opened before. For manually opened modal windows we don't use
   * timeout at all.
   */
  if (closeTimeout) {
    setTimeout(() => {
      closeModal(modalName)
    }, closeTimeout)
  }
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function getTopStickyElement() {
  if (!process.client) return null

  return document.getElementById(TOP_STICKY_CONTAINER_ID)
}

export function getWindowScrollTop() {
  if (!process.browser) return

  return (
    (window.pageYOffset || document.documentElement.scrollTop) -
    (document.documentElement.clientTop || 0)
  )
}

export function getWindowWidth() {
  if (!process.browser) return

  return (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  )
}

export function getWindowHeight() {
  if (!process.browser) return

  return (
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
  )
}

const DEFAULT_MOBILE_WIDTH = 320
const DEFAULT_DESKTOP_WIDTH = 1460

export const percentage = {
  mobile(contentWidth, containerWidth = DEFAULT_MOBILE_WIDTH) {
    return Math.round((contentWidth / containerWidth) * 100 * 100) / 100
  },
  desktop(contentWidth, containerWidth = DEFAULT_DESKTOP_WIDTH) {
    return Math.round((contentWidth / containerWidth) * 100 * 100) / 100
  }
}

export function generateAspectRatioStyle(aspectRatio = 1, width) {
  const heightPadding = Math.round((100 * 100) / +aspectRatio) / 100

  return {
    height: 0,
    ...(width ? { width: `${width}px` } : {}),
    paddingBottom: `${heightPadding}%`
  }
}

export function compareArrays(arr1, arr2) {
  if (
    !arr1 ||
    !arr2 ||
    arr1.length !== arr2.length ||
    !Array.isArray(arr1) ||
    !Array.isArray(arr2)
  )
    return false

  const sortedArr1 = arr1.sort()
  const sortedArr2 = arr2.sort()

  return sortedArr1.every((value, index) => value === sortedArr2[index])
}

export function emptyValuesToNull(list, initialObj) {
  // TODO: extend function - add possibility to check deep values
  const convertedData = Object.entries(pick(list, initialObj)).reduce(
    (acc, [key, val]) => Object.assign({}, acc, { [key]: val || null }),
    {}
  )

  return Object.assign({}, initialObj, convertedData)
}

export function blurActiveElement() {
  if (!process.client) return

  document.activeElement.blur()
}

export function isTrimmedStringNotEmpty(str) {
  if (!str) return false

  return str.trim().length > 0
}

export function parseJSON(json) {
  let parsed

  try {
    parsed = JSON.parse(json)
  } catch (e) {
    return null
  }
  return parsed
}

/**
 * Important: only works on client-side
 * @param token
 * @returns {Object|null}
 */
export function parseJwt(token) {
  if (process.server) {
    throw new Error('parseJwt can only be used on client-side')
  }

  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

/* eslint-disable */
function makeCancelablePromise(promise) {
  let hasCanceled_ = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => (hasCanceled_ ? resolve({ isCanceled: true }) : resolve(val)),
      error => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
    )
  })

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true
    }
  }
}
/* eslint-enable */

function scrollToTop(component) {
  if (!component) return

  if (process.client) {
    return component.$scrollTo('body')
  }
}
export function replaceDoubleQuotesBySingle(text) {
  if (!text || typeof text !== 'string') return text

  return text.replace(/"/g, "'")
}

/**
 * Applies Promise.all to all promises but doesn't throw error in case
 * promise is not required. Returns null in such case and log the error.
 *
 * @param {Object[]} promiseSettingsArray           - array of settings
 * @param {promise} promiseSettingsArray[].promise  - promise
 * @param {boolean} promiseSettingsArray[].required - is promise required
 */
/* eslint no-async-promise-executor: 0 */
export function promiseAllWithFallback(promiseSettingsArray, context) {
  let errorHandler = () => {}
  if (context) {
    errorHandler =
      context.$errorHandler || pathOr(null, ['app', '$errorHandler'], context)
  }

  const wrappedPromises = promiseSettingsArray.map(
    ({ promise, required, errorMessage, fallbackData }) =>
      new Promise(async (resolve, reject) => {
        try {
          const result = await promise
          resolve({ error: false, data: result })
        } catch (err) {
          if (required) {
            reject(err)
          } else {
            errorHandler(err, context, {
              showMessage: true,
              showErrorPage: false,
              ...(errorMessage ? { userMessage: errorMessage } : {})
            })
            resolve({ error: true, data: fallbackData || null })
          }
        }
      })
  )

  return Promise.all(wrappedPromises)
}

function parseTldrJSONToText(tldr) {
  try {
    const parsedTldr = JSON.parse(tldr)
    return Array.isArray(parsedTldr) ? parsedTldr.join('\n') : ''
  } catch (err) {
    /* No need to catch this error */
    return ''
  }
}

export function getFirstRegexGroups(regex, str) {
  if (!regex) return []

  if (!(regex.flags && regex.flags.includes('g'))) {
    console.error(
      'The infinite loop will occur unless /g is specified. ' +
        'More info: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/match#description'
    )

    return []
  }

  const groups = []
  let nextResult

  while ((nextResult = regex.exec(str)) !== null) {
    groups.push(nextResult[1])
  }

  return groups
}

export function isElementCaptchaOverlay(target) {
  /**
   * Since google doesn't provide any identifier for its captcha challenge overlay, we need to exclude
   * direct parents of the challenge (google appends it to the body)
   */
  const exclusionRuleTagNames = ['html', 'body']
  const parentTagName = pathOr('', ['parentElement', 'tagName'], target)

  if (
    !target.parentElement ||
    !parentTagName ||
    exclusionRuleTagNames.includes(parentTagName.toLowerCase())
  ) {
    return false
  }

  return !!target.parentElement.querySelector(GOOGLE_CAPTCHA_CHALLENGE_SELECTOR)
}

export function generateServerCacheKey(cacheKey) {
  if (!cacheKey) return guid()
  /** Apply component cache only for production environment.
   * (if we return componentName - component is cached, and cache invalidated only by timeout (see
   * @nuxtjs/component-cache module config)
   * (if we return new guid - component is not cached) **/
  return process.env.NODE_ENV === 'production' ? cacheKey : guid()
}

/**
 * Convert url (could be image) to blob file. Use with async/await
 * @param url
 * @returns Promise
 */
export function urlToBlob(url) {
  if (!url) return null

  try {
    return storeCopy.dispatch('requestUrlToBlob', url)
  } catch (err) {
    throw err
  }
}

function getFileNameFromUrl(url) {
  try {
    const parsedUrl = new URL(url)
    return parsedUrl.pathname.split('/').pop()
  } catch (err) {
    return ''
  }
}

export async function downloadFileFromUrl(url) {
  try {
    const fileName = getFileNameFromUrl(url)

    if (!fileName) return

    const response = await fetch(url)
    const blob = await response.blob()

    const blobUrl = URL.createObjectURL(blob)

    // Create a hidden anchor element and trigger a click to download the Blob
    const link = document.createElement('a')
    link.href = blobUrl
    link.download = fileName // Set the desired file name
    link.click()

    // Cleanup: Revoke the Blob URL after the download
    URL.revokeObjectURL(blobUrl)
  } catch (err) {
    console.error('Error downloading image:', err)
  }
}

function getPagePathWithoutHash() {
  const { href, origin } = window.location
  const urlWithoutHash = href.split('#')[0]
  const pathWithoutHash = urlWithoutHash.replace(origin, '')
  return {
    pathWithoutHash
  }
}

export function registerPageView() {
  if (!process.client || process.env.NODE_ENV !== 'production') {
    return
  }

  Vue.nextTick().then(() => {
    const { pathWithoutHash } = getPagePathWithoutHash()

    window.dataLayer.push({
      event: `PV ${pathWithoutHash}`
    })
  })
}

const insertStyleToHead = cssCode => {
  const style = document.createElement('style')
  const id = guid()

  style.id = id
  style.type = 'text/css'
  style.appendChild(document.createTextNode(cssCode))
  document.head.appendChild(style)
  return id
}

export const escapeHTMLTags = inputString => {
  if (!inputString) return ''

  return inputString.replace(/</gm, '&lt;').replace(/>/gm, '&gt;')
}

export const unescapeHTMLTags = inputString => {
  if (!inputString) return ''

  return inputString.replace(/&lt;/gm, '<').replace(/&gt;/gm, '>')
}

const removeStyleFromHeadById = id => {
  const styleEl = document.getElementById(id)
  if (!styleEl) return

  styleEl.parentElement.removeChild(styleEl)
}

export function removeEmptyObjectEntries(obj) {
  return Object.entries(obj)
    .filter(([key, value]) => !!value)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
}

export function serializeQueryParams(params) {
  const validParams = removeEmptyObjectEntries(params)
  return querystring.stringify(validParams)
}

/**
 * ToDo: Complete and check this function
 * @param {function}  fn
 * @param {number}    timeout
 * @param {boolean}   immediate
 */
export function runWithInterval(fn, timeout, immediate = true) {
  if (!fn || !timeout) return

  if (immediate) {
    fn()
  }

  let timeoutId

  function clearInterval() {
    clearTimeout(timeoutId)
  }

  ;(function runWithIntervalInner() {
    timeoutId = setTimeout(() => {
      fn()
      runWithIntervalInner()
    }, timeout)
  })()

  return {
    clearInterval
  }
}

export function isInnerLink(link) {
  const reg = new RegExp(`${storeCopy.$env.DOMAIN_URL}/?`, 'i')

  return reg.test(link) || !checkIfLinkHasProtocol(link)
}

export function isRecaptchaChallenge(element) {
  if (!element) return false

  return (
    element?.tagName?.toLowerCase() === HTML_TAG.IFRAME &&
    element?.src?.includes('google.com/recaptcha/')
  )
}

export function toCamelCase(string) {
  if (!string || typeof string !== 'string') return string

  return `${string[0].toLowerCase()}${string.slice(1)}`
}

export function processResponse(original, remap = {}) {
  return remapObjectKeys(original, remap, toCamelCase)
}

export function getTextWidth(string, font) {
  if (!string) return 0

  const charSizeMap = storeCopy.getters.getCharSizeMapByFont
  const textWidth = string
    .split('')
    .reduce(
      (acc, char) => acc + charSizeMap(font)[char] || FALLBACK_CHAR_WIDTH,
      0
    )
  return Math.ceil(textWidth)
}

export function isHashUrl(url) {
  return /^\/?#/.test(url)
}

export function mailto(mailTo) {
  if (!mailTo || !process.client) return

  const a = document.createElement('a')
  a.href = mailTo
  a.setAttribute('target', '_blank')
  a.click()
}

export function getBackendErrorData(err) {
  return pathOr(null, ['response', 'data'], err)
}

export async function appendQueryParams(queryParams, router) {
  if (!router) {
    console.error('Router is not passed')
    return
  }

  const currentRoute = router.currentRoute
  if (!queryParams || equals(queryParams, currentRoute.query)) {
    return
  }

  return new Promise(resolve => {
    router.replace(
      {
        ...currentRoute,
        query: {
          ...currentRoute.query,
          ...queryParams
        },
        params: {
          ...(currentRoute.params || {}),
          force: true
        }
      },
      resolve,
      resolve
    )
  })
}

export async function removeQueryParamByName(queryParamName, router) {
  if (!router) {
    console.error('Router is not passed')
    return
  }

  const currentRoute = router.currentRoute

  if (!queryParamName || currentRoute.query[queryParamName] === undefined) {
    return
  }

  return new Promise(resolve => {
    router.replace(
      {
        ...currentRoute,
        query: omit([queryParamName], currentRoute.query)
      },
      resolve,
      resolve
    )
  })
}

/**
 * Create dynamic watcher, fire method if condition function returns true and
 * remove watcher.
 * @param ctx         - component this to access this.$watch
 * @param field       - field to watch
 * @param method      - method to fire on watch
 * @param conditionFn - handle newVal and oldVal function, returns Boolean
 * @param immediate   - condition is checked immediately if set to true
 * @param timeoutFn   - execute on timeout
 * @param timeout     - timeout in ms
 */
export function watchAndExecuteOnce({
  ctx,
  field,
  handlerFn = () => {},
  conditionFn = () => true,
  immediate = false,
  timeoutFn = null,
  timeout = 2 * 60 * 1000
}) {
  if (!ctx || !ctx.$watch || !field) return

  if (immediate) {
    const currentValue = ctx[field]
    if (conditionFn(currentValue)) {
      handlerFn()
      return
    }
  }

  const unwatch = ctx.$watch(field, (newVal, oldVal) => {
    if (conditionFn(newVal, oldVal)) {
      handlerFn()
      unwatch()
    }
  })

  if (checkIfFunction(timeoutFn)) {
    setTimeout(() => {
      timeoutFn()
      unwatch()
    }, timeout)
  }
}

export function joinStringsWithSpaces(stringArray) {
  if (!isArray(stringArray)) return ''

  return stringArray.filter(v => v).join(' ')
}

export function generateArticlePathBySlugs({
  articleSlug,
  categorySlug,
  subcategorySlug,
  lowercase = true,
  endingSlash = true,
  amp = false
}) {
  if (!articleSlug || !categorySlug) return null

  const endingSlashHandler = v => addEndingSlash(v)
  const lowerCaseHandler = v => v.toLowerCase()

  const postHandlers = [
    ...(endingSlash ? [endingSlashHandler] : []),
    ...(lowercase ? [lowerCaseHandler] : [])
  ]
  const pathParts = [
    categorySlug,
    subcategorySlug,
    articleSlug,
    ...(amp ? ['amp'] : [])
  ]

  const articlePath = `/${pathParts.filter(v => !!v).join('/')}`

  return pipe(...postHandlers)(articlePath)
}

export const saveValueInClosure = val => ({
  getValue: () => val
})

function decodeAccessToken(token) {
  const char = token.slice(-1)
  const rawToken = token.slice(0, -1)
  const position = rawToken.length - (char.charCodeAt(0) - 96)
  const tokenFirstPart = rawToken.slice(position)
  const tokenSecondPart = rawToken.slice(0, position)

  return `${tokenFirstPart}${tokenSecondPart}`
}

export function getTokenValue(token) {
  if (!token) return token

  console.log('token', token)

  return decodeAccessToken(token.getValue())
}

export function fallbackIfUndefined(value, fallbackValue) {
  return value === undefined ? fallbackValue : value
}

/**
 * @link https://stackoverflow.com/questions/986937/how-can-i-get-the-browsers-scrollbar-sizes
 * Gets actual scrollbar width (15px on desktop, 0px on mobile)
 */
export function getScrollBarWidth() {
  if (!process.client) return null

  const inner = document.createElement('p')
  inner.style.width = '100%'
  inner.style.height = '200px'

  const outer = document.createElement('div')
  outer.style.position = 'absolute'
  outer.style.top = '0px'
  outer.style.left = '0px'
  outer.style.visibility = 'hidden'
  outer.style.width = '200px'
  outer.style.height = '150px'
  outer.style.overflow = 'hidden'
  outer.appendChild(inner)

  document.body.appendChild(outer)
  const w1 = inner.offsetWidth
  outer.style.overflow = 'scroll'
  let w2 = inner.offsetWidth
  if (w1 === w2) w2 = outer.clientWidth

  document.body.removeChild(outer)

  return w1 - w2
}

export function formatYoutubeIdToEmbedUrl(id) {
  if (!id) return null

  return `https://www.youtube.com/embed/${id}`
}

export function generateVideoSchemaPayloadFromResponse(video) {
  if (!video) return {}

  const {
    externalId,
    title,
    description,
    publishedOn,
    thumbnailUrl
  } = processResponse(video)

  return {
    name: title,
    description,
    thumbnailUrl,
    uploadDate: publishedOn,
    embedUrl: formatYoutubeIdToEmbedUrl(externalId)
  }
}

function isVideoValid(video) {
  if (!video) return false

  const { externalId, title, publishedOn, thumbnailUrl } = processResponse(
    video
  )

  return !!(externalId && title && publishedOn && thumbnailUrl)
}

export function generateVideoSchemasFromResponse(videos = []) {
  if (!isArray(videos)) return []

  return videos
    .filter(video => isVideoValid(video))
    .map(video => ({
      type: PAGE_SCHEME_TYPE.VIDEO_GENERAL,
      data: generateVideoSchemaPayloadFromResponse(video)
    }))
}

export function sliceText({ text = '', limit = Infinity }) {
  if (!isString(text)) return ''

  return text.length > limit ? `${text.slice(0, limit)}...` : text
}

export function promiseAllSettled(promises) {
  return Promise.all(
    promises.map(promise =>
      promise
        .then(value => ({
          status: PROMISE_STATUS.FULFILLED,
          value
        }))
        .catch(reason => ({
          status: PROMISE_STATUS.REJECTED,
          reason
        }))
    )
  )
}

/**
 * Used to detect if the browser is Safari
 *
 * Note: this might not be reliable for mobile versions of Safari
 * @returns {boolean}
 */
export function isSafariBrowser() {
  return window.safari !== undefined
}

/**
 * Used when processing content from MS Word
 * @param inputString
 * @return {*|string}
 */
export const cleanFromTypographicQuoteChars = inputString => {
  if (!inputString) return ''

  return inputString
    .replace(/”/g, '"')
    .replace(/“/g, '"')
    .replace(/‘/g, "'")
    .replace(/’/g, "'")
}

export const removeTypographicQuoteChars = inputString => {
  if (!inputString) return ''

  return inputString
    .replace(/”/g, '')
    .replace(/“/g, '')
    .replace(/‘/g, '')
    .replace(/’/g, '')
}

export function getBoundingClientRectCustom(el) {
  if (!el) return {}

  const width = el.offsetWidth
  const height = el.offsetHeight

  let _x = 0
  let _y = 0
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft
    _y += el.offsetTop - el.scrollTop
    el = el.offsetParent
  }

  return {
    top: _y,
    bottom: _y + height,
    left: _x,
    right: _x + width,
    width,
    height
  }
}

export function runOnRefResize({ dataRef = REFS.LAYOUT_PAGE_WRAP, fn }) {
  const el = getElByDataRef(dataRef)
  let isCancelled = false

  function handleResize(entries) {
    if (isCancelled) {
      resizeObserver.disconnect()
      return
    }

    for (const entry of entries) {
      const { target, contentRect } = entry
      const el = getElByDataRef(dataRef)
      const rectData = getBoundingClientRectCustom(el)
      fn({ target, contentRect, rectData })
    }
  }

  const resizeObserver = new ResizeObserver(handleResize)
  resizeObserver.observe(el)

  return function removeResizeListener() {
    resizeObserver.disconnect()
    isCancelled = true
  }
}

export function checkIfElementIsDataRef(el, dataRef) {
  if (!el || !el.getAttribute || !dataRef) return false

  return el.getAttribute('data-ref') === dataRef
}

const LAZY_HYDRATION_COMPONENT_NAME = 'LazyHydrationWrapper'

export function getComponentByRef(ref) {
  if (!ref) return ref

  const child = ref.$children && ref.$children[0]

  if (!child) return ref

  const refName = ref.$options.name

  return refName === LAZY_HYDRATION_COMPONENT_NAME ? child : ref
}

export async function registerStoreModule(store, moduleName) {
  const error = new Error(
    `The module ${moduleName} has not been registered in the store`
  )
  if (!store || !moduleName) {
    throw error
  }

  if (store.hasModule(moduleName)) return

  try {
    const { default: module } = await import(`../storeLazy/${moduleName}`)
    if (!module) {
      throw error
    }

    if (store.hasModule(moduleName)) {
      return
    }

    store.registerModule(moduleName, module, {
      preserveState: !!store.state[moduleName]
    })

    if (!store.hasModule(moduleName)) {
      throw error
    }
  } catch (err) {
    throw err
  }
}

export function mapActionsLazy(actionMap) {
  return Object.entries(actionMap).reduce((acc, [methodName, actionPath]) => {
    return {
      ...acc,
      async [methodName](...args) {
        if (!this.$store) {
          return
        }

        const moduleName = actionPath.split('/')[0]

        await registerStoreModule(this.$store, moduleName)

        return this.$store.dispatch(actionPath, ...args)
      }
    }
  }, {})
}

/**
 * @link https://stackoverflow.com/questions/37973290/javascript-bind-method-does-not-work-on-getter-property
 * @link https://stackoverflow.com/questions/53332250/dynamic-computed-property-name
 * @link https://tenmilesquare.com/resources/software-development/understanding-mapgetters-in-vuex/
 */
export function generateReactiveGetters({
  moduleName,
  getterName,
  computedName,
  initialValue
}) {
  const getterContainer = {}

  getterContainer[computedName] = function() {
    if (this.$store.hasModule(moduleName)) {
      return this.$store.getters[`${moduleName}/${getterName}`]
    }

    registerStoreModule(this.$store, moduleName).then(() => {
      if (this.$store.hasModule(moduleName)) {
        result.value = guid() // just to trigger the getter update
      }
    })

    const result = Vue.observable({
      value: initialValue
    })

    return result.value
  }
  getterContainer[computedName].vuex = true
  return getterContainer
}

export function mapGettersLazy(gettersMap) {
  const mappedGetters = Object.entries(gettersMap).reduce(
    (acc, [computedName, getterPath]) => {
      const [moduleName, getterName] = getterPath.split('/')

      const {
        initialState,
        getters
      } = require(`@/storeLazy/${moduleName}-enums.js`)
      const initialValue = getters[getterName](initialState)

      return {
        ...acc,
        ...generateReactiveGetters({
          moduleName,
          getterName,
          computedName,
          initialValue
        })
      }
    },
    {}
  )

  return mappedGetters
}

const helperMethods = {
  formatDate: {
    /** Phase 1: It was decided to always show timestamps in user local time.
     *  If you are looking for the solution which allows to show time
     *  in specific Timezone - please consider the Luxon library
     *  (and check the previous commits - such a solution was used previously)
     *  **/
    toDefault: (dateStr, forceUtc = false) => {
      if (!dateStr) return ''

      const { EEEE, MM, yyyy, dd, HH, mm, ZZZZ } = getParsedDateString(
        dateStr,
        forceUtc
      )

      return `${EEEE}, ${dd}/${MM}/${yyyy} | ${HH}:${mm} ${ZZZZ}`
    },
    toDateAndHours: dateStr => {
      if (!dateStr) return ''

      const { MM, yyyy, dd, HH, mm, ZZZZ } = getParsedDateString(dateStr)

      return `${dd}/${MM}/${yyyy} | ${HH}:${mm} ${ZZZZ}`
    },
    toOffset: formatDateToOffset,
    toWeekDay: dateStr => {
      if (!dateStr) return ''

      const { EEEE, MM, yyyy, dd, ZZZZ } = getParsedDateString(dateStr)

      return `${EEEE}, ${dd}/${MM}/${yyyy} ${ZZZZ}`
    },
    toWeekDayWithoutTimezone: dateStr => {
      if (!dateStr) return ''

      const { EEEE, MM, yyyy, dd } = getParsedDateString(dateStr)

      return `${EEEE}, ${dd}/${MM}/${yyyy}`
    },
    toISO: dateStr => {
      if (!dateStr) return ''

      const { MM, yyyy, dd, HH, mm, ss } = getParsedDateString(dateStr)

      return `${yyyy}-${MM}-${dd}T${HH}:${mm}:${ss}`
    },
    toIntervalFromNow: fromNow,
    toDateRange: (startDate, endDate) => {
      if (!startDate || !endDate) return ''

      const { d: startDay } = getParsedDateString(startDate)
      const { d: endDay, yyyy, MMMM } = getParsedDateString(endDate)

      return `${startDay} - ${endDay} ${MMMM} ${yyyy}`
    }
  },
  resetComponentData,
  guid,
  removeSpaces,
  formatPhone,
  isValidationFailed,
  replaceImageUrlSuffix,
  getLinkToFmBucketFile,
  getLinkToFmDirBucketFile,
  getExtension,
  getMimeTypeByExtension,
  addEndingSlash,
  removeEndingSlashes,
  closeModal,
  closeAllModals,
  openModal,
  addStartingSlash,
  removeStartingSlash,
  unwrapFromSlashes,
  wrapIntoSlashes,
  prettifyPath,
  getMediaFileType,
  fileTypeChecker,
  formatPrice,
  applyFallback,
  isMiddlewareSkipped,
  checkIfRefreshTokenIsRelevant,
  stringToArray,
  arrayToString,
  isString,
  isStringEndsWith,
  isObject,
  isArray,
  isFile,
  valuesToLowercase,
  getFullPath,
  htmlToText,
  countWordsAmount,
  calculateTimeRequiredToRead,
  generateSlugFromName,
  capitalize,
  getWindowScrollTop,
  percentage,
  generateAspectRatioStyle,
  compareArrays,
  emptyValuesToNull,
  blurActiveElement,
  makeCancelablePromise,
  scrollToTop,
  replaceDoubleQuotesBySingle,
  promiseAllWithFallback,
  isAmpPage,
  isNotAmpPage,
  checkIfFunction,
  getImageDimensions,
  parseTldrJSONToText,
  parseJSON,
  hideOneSignalElement,
  showOneSignalElement,
  getFirstRegexGroups,
  generateServerCacheKey,
  urlToBlob,
  downloadFileFromUrl,
  getPaginationSeoLinks,
  isUrlWithPagination,
  getPageNumberFromUrl,
  getPageNumberFromRoute,
  insertStyleToHead,
  removeStyleFromHeadById,
  escapeHTMLTags,
  removeEmptyObjectEntries,
  serializeQueryParams,
  runWithInterval,
  isElementCaptchaOverlay,
  checkIfLinkHasProtocol,
  isInnerLink,
  remapObjectKeys,
  processResponse,
  forceUrlFetch,
  getTextWidth,
  mailto,
  getBinaryStringFromBooleanArray,
  getBackendErrorCode,
  getBackendErrorData,
  getBackendErrorEvidence,
  appendQueryParams,
  removeQueryParamByName,
  isStringStartsWith,
  watchAndExecuteOnce,
  joinStringsWithSpaces,
  pollUntil,
  getOptanonActiveGroups,
  insertScriptToHead,
  getAltTextForMediaCenterImage,
  generateArticlePathBySlugs,
  isTrimmedStringNotEmpty,
  getNthPathParam,
  getLastPathParam,
  saveValueInClosure,
  getTokenValue,
  removePaginationPartFromPath,
  getUserDetailsFromAccessToken,
  getUrlHost,
  fallbackIfUndefined,
  getWindowWidth,
  getWindowHeight,
  getScrollBarWidth,
  generateParsedDomElementsHandlerDeep,
  formatYoutubeIdToEmbedUrl,
  generateVideoSchemaPayloadFromResponse,
  generateVideoSchemasFromResponse,
  getUrlSizeSuffix,
  isImageUrlHasSizeSuffixes,
  replaceImageUrlSuffixWithOriginal,
  sliceText,
  isSafariBrowser,
  promiseAllSettled,
  cleanFromTypographicQuoteChars,
  removeTypographicQuoteChars,
  getTopStickyElement,
  runOnRefResize,
  checkIfElementIsDataRef,
  isHashUrl,
  getComponentByRef,
  getElByDataRef,
  isRecaptchaChallenge,
  parseJwt
}

const helperPlugin = {
  install(Vue) {
    Vue.prototype.$helper = helperMethods
  }
}

Vue.use(helperPlugin)

export default (ctx, inject) => {
  context = ctx
  storeCopy = ctx.store
  inject('helper', helperMethods)
}

function fromNow(dateStr, withPrefix = true) {
  const diff = new Date(dateStr) - new Date()
  const diffAbs = Math.abs(diff)
  let dimension = ''

  const dimensions = {
    minutes: 'minutes',
    hours: 'hours',
    days: 'days',
    weeks: 'weeks',
    months: 'months',
    years: 'years'
  }

  const parsedDiff = {
    [dimensions.minutes]: Math.round(diffAbs / (1000 * 60)),
    [dimensions.hours]: Math.round(diffAbs / (1000 * 60 * 60)),
    [dimensions.days]: Math.round(diffAbs / (1000 * 60 * 60 * 24)),
    [dimensions.weeks]: Math.round(diffAbs / (1000 * 60 * 60 * 24 * 7)),
    [dimensions.months]: Math.round(diffAbs / (1000 * 60 * 60 * 24 * 30)),
    [dimensions.years]: Math.round(diffAbs / (1000 * 60 * 60 * 24 * 30 * 12))
  }

  if (parsedDiff.minutes < 60) {
    dimension = dimensions.minutes
  } else if (parsedDiff.hours < 24) {
    dimension = dimensions.hours
  } else if (parsedDiff.days < 7) {
    dimension = dimensions.days
  } else if (parsedDiff.weeks < 4) {
    dimension = dimensions.weeks
  } else if (parsedDiff.months < 12) {
    dimension = dimensions.months
  } else {
    dimension = dimensions.years
  }

  const val = parsedDiff[dimension]

  if (val <= 10 && dimension === dimensions.minutes) {
    return 'just now'
  } else {
    dimension = val > 1 ? dimension : dimension.replace('s', '')
    if (withPrefix) {
      return diff < 0 ? `${val} ${dimension} ago` : `in ${val} ${dimension}`
    } else {
      return `${val} ${dimension}`
    }
  }
}

function getOneSignalElement() {
  return document.getElementById(ONE_SIGNAL_ICON_CONTAINER_ID)
}

function setStyleForElement(element, property, value) {
  if (!element) return

  element.style[property] = value
}

function removeSpecificStyleFromInlineStyles(element, property) {
  if (!element) return

  element.style.removeProperty(property)
}

function hideOneSignalElement() {
  setStyleForElement(getOneSignalElement(), 'display', 'none')
}

export function getAltTextForMediaCenterImage(image) {
  if (!isObject(image)) return null

  const remappedImage = processResponse(image)

  const { isOld, altText, title } = remappedImage

  return isOld ? altText : altText || title
}

function showOneSignalElement() {
  removeSpecificStyleFromInlineStyles(getOneSignalElement(), 'display')
}

export function getOptanonActiveGroups() {
  return storeCopy.getters['one-trust/optanonActiveGroups']
}

function isScriptAlreadyInContainer(script, selector) {
  if (script.src) {
    return !!document.querySelector(`${selector} script[src="${script.src}"]`)
  }

  if (script.innerText) {
    const scriptsInSelector = document.querySelectorAll(`${selector} script`)

    return !![...scriptsInSelector].find(
      scriptInHead => scriptInHead.innerText === script.innerText
    )
  }

  console.error(
    "The provided script doesn't have neither src nor innerText attributes"
  )
  return false
}

export function insertScriptToHead(scriptElement) {
  if (
    !scriptElement ||
    (!scriptElement.src && !scriptElement.innerText) ||
    isScriptAlreadyInContainer(scriptElement, 'head')
  ) {
    return
  }

  document.head.appendChild(scriptElement)
}

export function getSingleDomNodeFromHtml(html) {
  if (!html) return null

  const elementWrapper = document.createElement('template')
  elementWrapper.innerHTML = html
  return elementWrapper.content.firstChild
}

export function getMultipleDomNodesFromHtml(html) {
  if (!html) return null

  const elementWrapper = document.createElement('template')
  elementWrapper.innerHTML = html
  return elementWrapper.content.childNodes
}

export function removeNode(node) {
  if (!node || !node.parentNode) return

  node.parentNode.removeChild(node)
}
