import Vue from 'vue'
import { pathOr } from 'ramda'

import * as types from '~/store/mutation-types'
import { cachedRequest } from '@/utils/jobRequestApiMethods'
import { HEADER_STATE } from 'enums/header'
import { CACHE_CONTROL_15_SEC_CDN_CACHE } from '~/utils/enums/caching'
import { COMPONENT_TYPE } from 'enums/component-type'
import { API_VERSION } from 'enums/api'
import apiV2 from '@/utils/apiV2'

const HEADER_SCROLL_TOP_OFFSET = 60

export const state = () => ({
  isPageNavigationInProgress: false,
  windowWidth: 0,
  windowHeight: 0,
  mobileBottomPadding: 0,
  scrollTop: 0,
  isHeaderCollapsed: false,
  isScrollbarHidden: false,
  headerRectBottomValue: 0,
  isHeaderAtTop: false,
  scrollTopOnHideScroll: 0,
  previousScrollTop: 0,
  currentUrl: '',
  routerNavigatedTo: null,
  routerNavigatedToFullPath: null,
  routerNavigatedFrom: null,
  userAgent: '',
  isIPhone: false,
  isSafari: false,
  headerMenuItems: null,
  headerMenuItemsLastModified: null,
  footerMenuItems: null,
  footerMenuItemsLastModified: null,
  stayConnected: null,
  stayConnectedLastModified: null,
  globalSpinnerVisible: false,
  isPageLoaded: false,
  isCurrentUserBot: false,
  googleBanners: {
    isGptLoadStarted: false,
    isGptLoaded: false,
    isBannersInitialized: false,
    slots: {},
    activeSlots: {},
    bannerCampaignIsEmptyById: {}
  },
  charSizeByFont: {},
  isInterstitialBannerVisible: false,
  isInterstitialBannerPending: false,
  isMakeYourAccountFlowPending: true,
  isStickyWidgetsPending: true,
  isBrowserTabActiveAndFocused: true,
  isPreviewMode: false,
  isCaptchaLoaded: false,
  isCaptchaLoadingInProgress: false,
  isDisqusWidgetLoaded: false,
  isYoutubeApiLoaded: false,
  userAgentDetails: null,
  isOnline: true
})

export const getters = {
  isPageNavigationInProgress: state => state.isPageNavigationInProgress,
  windowWidth(state) {
    return state.windowWidth
  },
  windowHeight(state) {
    return state.windowHeight
  },
  isScrollbarHidden: state => state.isScrollbarHidden,
  isHeaderCollapsed: state =>
    state.isHeaderAtTop && state.scrollTop > HEADER_SCROLL_TOP_OFFSET,
  headerRectBottomValue: state => state.headerRectBottomValue,
  isHeaderAtTop: state => state.isHeaderAtTop,
  currentHeaderState(state, getters) {
    return getters.isHeaderCollapsed
      ? HEADER_STATE.COLLAPSED
      : HEADER_STATE.EXPANDED
  },
  mobileBottomPadding(state) {
    return state.mobileBottomPadding
  },
  routerNavigatedTo(state) {
    return state.routerNavigatedTo
  },
  routerNavigatedToFullPath(state) {
    return state.routerNavigatedToFullPath
  },
  routerNavigatedFrom(state) {
    return state.routerNavigatedFrom
  },
  headerMenuItemsLastModified: state => state.headerMenuItemsLastModified,
  footerMenuItemsLastModified: state => state.footerMenuItemsLastModified,
  stayConnectedLastModified: state => state.stayConnectedLastModified,
  headerAllNews(state) {
    const list = state.headerMenuItems
    return pathOr([], ['AllNews', 'ParentItems'], list)
  },
  headerEducation(state) {
    const list = state.headerMenuItems
    return pathOr([], ['Education', 'ParentItems'], list)
  },
  headerEducationLink(state) {
    const list = state.headerMenuItems
    return pathOr(null, ['Education', 'Link'], list)
  },
  footerLeftSection(state) {
    const list = state.footerMenuItems
    return pathOr([], ['LeftSection'], list)
  },
  footerRightSection(state) {
    const list = state.footerMenuItems
    return pathOr([], ['RightSection'], list)
  },
  stayConnected(state) {
    const list = state.stayConnected
    return pathOr([], ['Channels'], list)
  },
  isIPhone(state) {
    return state.isIPhone
  },
  isSafari(state) {
    return state.isSafari
  },
  isAppleBrowser(state) {
    return state.isIPhone || state.isSafari
  },
  userAgent: state => state.userAgent,
  scrollTopOnHideScroll: state => state.scrollTopOnHideScroll,
  userAgentDetails: state => state.userAgentDetails,
  browserDetails: state => state.userAgentDetails?.browser,
  isPageLoaded: state => state.isPageLoaded,
  scrollTop: state => state.scrollTop,
  previousScrollTop: state => state.previousScrollTop,
  isGptLoadStarted: state => state.googleBanners.isGptLoadStarted,
  isBannersInitialized: state => state.googleBanners.isBannersInitialized,
  bannerSlots: state => state.googleBanners.slots,
  bannerCampaignIsEmptyById: state =>
    state.googleBanners.bannerCampaignIsEmptyById,
  activeBannerSlots: state => state.googleBanners.activeSlots,
  getCharSizeMapByFont: state => font => state.charSizeByFont[font],
  isInterstitialBannerVisible: state => state.isInterstitialBannerVisible,
  isInterstitialBannerPending: state => state.isInterstitialBannerPending,
  isMakeYourAccountFlowPending: state => state.isMakeYourAccountFlowPending,
  isStickyWidgetsPending: state => state.isStickyWidgetsPending,
  isBrowserTabActiveAndFocused: state => state.isBrowserTabActiveAndFocused,
  isCurrentUserBot: state => state.isCurrentUserBot,
  isPreviewMode: state => state.isPreviewMode,
  isCaptchaLoaded: state => state.isCaptchaLoaded,
  isCaptchaLoadingInProgress: state => state.isCaptchaLoadingInProgress,
  isDisqusWidgetLoaded: state => state.isDisqusWidgetLoaded,
  isYoutubeApiLoaded: state => state.isYoutubeApiLoaded,
  isOnline: state => state.isOnline
}

export const actions = {
  async nuxtServerInit({ dispatch }, { res }) {
    res.setHeader('Cache-Control', CACHE_CONTROL_15_SEC_CDN_CACHE)

    /**
     *  Server Actions List
     **/

    const requestHeaderMenuItems = async () => {
      try {
        await dispatch('requestHeaderMenuItems')
      } catch (err) {
        console.log(err)
      }
    }

    const requestFooterMenuItems = async () => {
      try {
        await dispatch('requestFooterMenuItems')
      } catch (err) {
        console.log(err)
      }
    }

    const requestStayConnected = async () => {
      try {
        await dispatch('requestStayConnected')
      } catch (err) {
        console.log(err)
      }
    }

    const requestErrorPageSeo = async () => {
      try {
        return await dispatch('seo/requestErrorPageSeo', null, { root: true })
      } catch (e) {
        console.log(e)
      }
    }

    await Promise.all([
      requestHeaderMenuItems(),
      requestFooterMenuItems(),
      requestStayConnected(),
      requestErrorPageSeo()
    ])
  },
  async requestHeaderMenuItems({ commit }) {
    const { data, lastModified } = await cachedRequest({
      method: this.$axiosV2.get,
      args: [apiV2.layout.getHeaderMenuList()],
      apiVersion: API_VERSION.V2
    })

    commit(types.SET_HEADER_ITEMS, { data, lastModified })
    return data
  },
  async requestFooterMenuItems({ commit }) {
    const { data, lastModified } = await cachedRequest({
      method: this.$axiosV2.get,
      args: [
        apiV2.layout.getFooterMenuList(),
        {
          params: {
            componentType: COMPONENT_TYPE.FM
          }
        }
      ],
      apiVersion: API_VERSION.V2
    })

    commit(types.SET_FOOTER_ITEMS, { data, lastModified })
    return data
  },
  async requestStayConnected({ commit }) {
    const { data: StayConnectedChannels, lastModified } = await cachedRequest({
      method: this.$axiosV2.get,
      args: [apiV2.layout.getStayConnected()],
      apiVersion: API_VERSION.V2
    })

    commit(types.SET_STAY_CONNECTED, {
      data: StayConnectedChannels,
      lastModified
    })
    return StayConnectedChannels
  },
  setWindowWidth({ commit }, windowWidth) {
    commit(types.SET_WINDOW_WIDTH, windowWidth)
  },
  setWindowHeight({ commit }, WindowHeight) {
    commit(types.SET_WINDOW_HEIGHT, WindowHeight)
  },
  setMobileBottomPadding({ commit }, mobileBottomPadding) {
    commit(types.SET_MOBILE_BOTTOM_PADDING, mobileBottomPadding)
  },
  setScrollTop({ commit }, top) {
    commit(types.SET_SCROLL_TOP, top)
  },
  setHeaderAtTopState({ commit }, value) {
    commit(types.SET_HEADER_AT_TOP_STATE, value)
  },
  setHeaderBottomRectValue({ commit }, value) {
    commit(types.SET_HEADER_BOTTOM_RECT_VALUE, value)
  },
  setPageLoaded({ commit }, status) {
    commit(types.SET_PAGE_LOADING_STATUS, status)
  },
  setRouterNavigationDetails({ commit }, data) {
    commit(types.SET_ROUTER_NAVIGATION_DETAILS, data)
  },
  setGptLoadStartedStatus({ commit }) {
    commit(types.SET_GPT_LOAD_STARTED_STATUS)
  },
  setBannersInitStatus({ commit }) {
    commit(types.SET_BANNER_INIT_STATUS)
  },
  setIsEmptyStatusForBannerCampaign({ commit }, { id, status }) {
    commit(types.SET_IS_EMPTY_STATUS_FOR_BANNER_CAMPAIGN, { id, status })
  },
  deleteIsEmptyStatusForBannerCampaign({ commit }, id) {
    commit(types.DELETE_IS_EMPTY_STATUS_FOR_BANNER_CAMPAIGN, id)
  },
  setBannerSlot({ commit, getters }, { id, slot }) {
    commit(types.SET_BANNER_SLOT, { id, slot })
  },
  setActiveBannerSlot({ commit }, slot) {
    commit(types.SET_ACTIVE_BANNER_SLOT, slot)
  },
  removeBannerSlot({ commit, state }, { key, id }) {
    if (window.googletag && window.googletag.destroySlots) {
      window.googletag.destroySlots([state.googleBanners.slots[id]])
    }

    commit(types.REMOVE_ACTIVE_BANNER_SLOT, key)
    commit(types.REMOVE_BANNER_SLOT, id)
    commit(types.DELETE_IS_EMPTY_STATUS_FOR_BANNER_CAMPAIGN, id)
  },
  setDocumentVisibilityState({ commit }, isTabActive) {
    commit(types.SET_DOCUMENT_VISIBILITY_STATE, isTabActive)
  },
  async requestUrlToBlob(_, url) {
    try {
      const options = {
        responseType: 'blob'
      }
      const { data } = await this.$axios.get(url, options)

      return data
    } catch (err) {
      throw err
    }
  },
  setIsOnlineState({ commit }, isOnline) {
    commit(types.SET_IS_ONLINE_STATE, isOnline)
  }
}

export const mutations = {
  [types.SET_PAGE_NAVIGATION_IN_PROGRESS_STATE](state, value) {
    state.isPageNavigationInProgress = value
  },
  [types.SET_WINDOW_WIDTH](state, windowWidth) {
    state.windowWidth = windowWidth
  },
  [types.SET_WINDOW_HEIGHT](state, windowHeight) {
    state.windowHeight = windowHeight
  },
  [types.SET_IS_SCROLLBAR_HIDDEN](state, isScrollbarHidden) {
    state.isScrollbarHidden = isScrollbarHidden
  },
  [types.SET_MOBILE_BOTTOM_PADDING](state, mobileBottomPadding) {
    state.mobileBottomPadding = mobileBottomPadding
  },
  [types.SET_SCROLL_TOP](state, top) {
    state.previousScrollTop = state.scrollTop
    state.scrollTop = top
  },
  [types.SET_HEADER_AT_TOP_STATE](state, value) {
    state.isHeaderAtTop = value
  },
  [types.SET_HEADER_BOTTOM_RECT_VALUE](state, value) {
    state.headerRectBottomValue = value
  },
  [types.SET_INITIAL_DEVICE](state, { isIPhoneDevice, isSafariBrowser }) {
    state.isIPhone = isIPhoneDevice
    state.isSafari = isSafariBrowser
  },
  [types.SET_HEADER_ITEMS](state, { data, lastModified }) {
    state.headerMenuItems = data.Menus
    state.headerMenuItemsLastModified = lastModified
  },
  [types.SET_SCROLL_TOP_ON_HIDE_SCROLL](state, value) {
    state.scrollTopOnHideScroll = value
  },
  [types.SET_FOOTER_ITEMS](state, { data, lastModified }) {
    state.footerMenuItems = data
    state.footerMenuItemsLastModified = lastModified
  },
  [types.SET_STAY_CONNECTED](state, { data, lastModified }) {
    state.stayConnected = data
    state.stayConnectedLastModified = lastModified
  },
  [types.SET_ROUTER_NAVIGATION_DETAILS](
    state,
    { toName, toFullPath, fromName }
  ) {
    state.routerNavigatedFrom = fromName
    state.routerNavigatedTo = toName
    state.routerNavigatedToFullPath = toFullPath
  },
  [types.SET_USER_AGENT](state, userAgent) {
    state.userAgent = userAgent || ''
  },
  [types.SET_SPINNER_STATUS](state, status) {
    state.globalSpinnerVisible = status
  },
  [types.SET_PAGE_LOADING_STATUS](state, status) {
    state.isPageLoaded = status
  },
  [types.SET_PREVIEW_MODE](state, value) {
    state.isPreviewMode = value
  },
  // GPT Banners
  [types.SET_GPT_LOAD_STARTED_STATUS](state) {
    state.googleBanners.isGptLoadStarted = true
  },
  [types.SET_BANNER_INIT_STATUS](state) {
    state.googleBanners.isBannersInitialized = true
  },
  [types.SET_IS_EMPTY_STATUS_FOR_BANNER_CAMPAIGN](state, { id, status }) {
    const { bannerCampaignIsEmptyById } = state.googleBanners

    state.googleBanners.bannerCampaignIsEmptyById = {
      ...bannerCampaignIsEmptyById,
      [id]: status
    }
  },
  [types.DELETE_IS_EMPTY_STATUS_FOR_BANNER_CAMPAIGN](state, idToRemove) {
    Vue.delete(state.googleBanners.bannerCampaignIsEmptyById, idToRemove)
  },
  [types.SET_BANNER_SLOT](state, { id, slot }) {
    state.googleBanners.slots = {
      ...state.googleBanners.slots,
      [id]: slot
    }
  },
  [types.SET_ACTIVE_BANNER_SLOT](state, slot) {
    state.googleBanners.activeSlots = {
      ...state.googleBanners.activeSlots,
      ...slot
    }
  },
  [types.REMOVE_ACTIVE_BANNER_SLOT](state, key) {
    Vue.delete(state.googleBanners.activeSlots, key)
  },
  [types.REMOVE_BANNER_SLOT](state, id) {
    Vue.delete(state.googleBanners.slots, id)
  },
  [types.SET_CHAR_SIZE](state, sizeMap) {
    state.charSizeByFont = sizeMap
  },
  [types.SET_INTERSTITIAL_BANNER_AS_VISIBLE](state) {
    state.isInterstitialBannerVisible = true
  },
  [types.SET_INTERSTITIAL_BANNER_AS_HIDDEN](state) {
    state.isInterstitialBannerVisible = false
  },
  [types.SET_INTERSTITIAL_BANNER_PENDING_STATE](state, value) {
    state.isInterstitialBannerPending = value
  },
  [types.SET_MAKE_YOUR_ACCOUNT_BETTER_FLOW_PENDING_STATUS](state, value) {
    state.isMakeYourAccountFlowPending = value
  },
  [types.SET_STICKY_WIDGETS_AS_NOT_PENDING](state) {
    state.isStickyWidgetsPending = false
  },
  [types.SET_DOCUMENT_VISIBILITY_STATE](state, value) {
    state.isBrowserTabActiveAndFocused = value
  },
  [types.SET_IS_CURRENT_USER_BOT](state, value) {
    state.isCurrentUserBot = value
  },
  [types.SET_CAPTCHA_LOAD_STATUS](state, value) {
    state.isCaptchaLoaded = value
  },
  [types.SET_CAPTCHA_LOADING_IN_PROGRESS_STATUS](state, value) {
    state.isCaptchaLoadingInProgress = value
  },
  [types.SET_DISQUS_WIDGET_LOAD_STATUS](state, value) {
    state.isDisqusWidgetLoaded = value
  },
  [types.SET_YOUTUBE_API_LOAD_STATUS](state, value) {
    state.isYoutubeApiLoaded = value
  },
  [types.SET_USER_AGENT_DETAILS](state, value) {
    state.userAgentDetails = value
  },
  [types.SET_IS_ONLINE_STATE](state, isOnline) {
    state.isOnline = isOnline
  }
}
